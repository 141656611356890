<template>
  <div>
    <div v-if="usertype!='admin'">
     <Headers />
    </div>
     <div class="content-page " v-if="user_level==0"><div class="content no_premision" v-html="lan.no_premision"></div></div>
     <div :class="usertype!='admin'?'content-page':'' " v-if="user_level==1">
      <div class="content" >
  
      <div class="load" v-show="isLoading2==true"><div class="lang" v-html="lan.loading"></div>...</div>
       
    
      <div class="row steps" >
  
        <div class="col-12">
          <div class="card-box">
            <h3><div class="lang" v-html="lan.login_user_manager"></div></h3>
              <div class="body"><div class="lang" v-html="lan.login_user_manager_text"></div></div>
              <div class="body" style="display: none;"><textarea v-model="mail_content"></textarea></div>
            <div class="line">
                <table class="ulist"><tr class="thead"><td>NO.</td>
                  <td><div class="lang" v-html="lan.email"></div></td>
                  <td><div class="lang" v-html="lan.lyz_first_name"></div></td>
                  <td><div class="lang" v-html="lan.lyz_last_name"></div></td>
                  <td><div class="lang" v-html="lan.lyz_dept"></div></td>
                  <td><div class="lang" v-html="lan.lyz_job"></div></td>
                  <td><div class="lang" v-html="lan.status"></div></td>
                  <td width="180"></td>
                  <td width="80"><div class="lang" v-html="lan.parent"></div></td>
                </tr>
                  <tr class="tbody even" >
                  <td>1</td>
                  <td>{{email}}</td>
                  <td>{{uinfo['担当者・姓']}}</td>
                  <td>{{uinfo['担当者・名']}}</td>
                  <td>{{uinfo['担当者・部署名']}}</td>
                  <td>{{uinfo['担当者・役職']}}</td>
                  <td></td>   
                  <td><input type="radio" :value="email" v-model="default_pid" @click="default_pid=email"></td>
                </tr>
                  <tr class="tbody even" v-for="(item,index) in children_list " :key="index">
                  <td>{{index+2}}</td>
                  <td>{{item['メールアドレス']}}</td>
                  <td>{{item['担当者・姓']}}</td>
                  <td>{{item['担当者・名']}}</td>
                  <td>{{item['担当者・部署名']}}</td>
                  <td>{{item['担当者・役職']}}</td>
                  <td>{{item['user_status_name']}}<button v-if="item['user_status']=='2'" class="btn btn-edit user-edit"  @click="resendMail(index)"><div class="lang" v-html="lan.rensend_mail"></div></button></td>
                  <td style="text-align: center;"><button class="btn btn-edit user-edit"  @click="editChildrenUser(index)"><div class="lang" v-html="lan.edit"></div></button>
                    &nbsp;&nbsp;&nbsp;
                    <button class="btn btn-danger user-delete"  @click="deleteChildrenUser(index)"><div class="lang" v-html="lan.delete"></div></button>
                  </td>   
                  <td><input type="radio" :value="item['メールアドレス']" v-model="default_pid" @click="default_pid=item['メールアドレス']"></td>
                </tr>
              </table>
              <button class="btn addnew" @click="saveppid" v-if="default_pid!=email"><div class="lang" v-html="lan.save"></div></button> 
                  <div class="line ubox" v-if="can_add">
                  
                    <div class="line"><div class="left-box"><div class="lang" v-html="lan.email"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box"> <input v-model="adduser['email']" :placeholder="lan.placeholder_email"></div> </div>
                    <div class="line"><div class="left-box"><div class="lang" v-html="lan.ly_user_1"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box" ><input v-model="adduser['担当者・姓']" :placeholder="lan.placeholder_first_name"> <input v-model="adduser['担当者・名']" :placeholder="lan.placeholder_last_name"></div> </div>
                    <div class="line"><div class="left-box"><div class="lang" v-html="lan.department_job"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box"><input v-model="adduser['担当者・部署名']" :placeholder="lan.placeholder_department"> <input v-model="adduser['担当者・役職']" :placeholder="lan.placeholder_job"></div> </div>
                      <button class="btn save" @click="addChlldrenUser()"><div class="lang" v-html="lan.save"></div></button> <button class="btn" @click="cannel()" :title="lan.cancel_warning"><div class="lang" v-html="lan.cancel"></div></button>
                  </div> 
                  <div class="line ubox" v-if="can_update">
                  
                  <div class="line"><div class="left-box"><div class="lang" v-html="lan.email"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box"> <input v-model="upduser['email']" :placeholder="lan.placeholder_email" disabled>&nbsp;&nbsp;<div class="lang" v-html="lan.email_alter"></div></div> </div>
                  <div class="line"><div class="left-box"><div class="lang" v-html="lan.ly_user_1"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box" ><input v-model="upduser['担当者・姓']" :placeholder="lan.placeholder_first_name"> <input v-model="upduser['担当者・名']" :placeholder="lan.placeholder_last_name"></div> </div>
                  <div class="line"><div class="left-box"><div class="lang" v-html="lan.department_job"></div><span class="required"><div class="lang" v-html="lan.required"></div></span></div><div class="right-box"><input v-model="upduser['担当者・部署名']" :placeholder="lan.placeholder_department"> <input v-model="upduser['担当者・役職']" :placeholder="lan.placeholder_job"></div> </div>
                    <button class="btn save" @click="updateChlldrenUser()"><div class="lang" v-html="lan.save"></div></button> <button class="btn" @click="cannel()" :title="lan.cancel_warning"><div class="lang" v-html="lan.cancel"></div></button>
                </div> 
              </div>
              
            <button class="btn addnew" @click="can_add=1;can_update=0" v-if="children_list.length+1<uinfo['利用ユーザー数'] && user_level==1">+<div class="lang" v-html="lan.add"></div></button>         
        </div>
      </div>
    </div>
    </div>
  </div>
   <div v-if="usertype!='admin'">
    <Footer />
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import { inject } from 'vue';
import Headers from '@/components/Headers.vue'
import Footer from '@/components/Footer.vue'
import { getNdate } from '@/components/common'
export default {
  name: 'CorpList',
  components: {
    Headers,Footer,
  },
  data() {
    return {
        uinfo:{},
        dataType:1,
        cur_step:3,
        lan:inject('global').creis_language_data,
        cur_lan:localStorage.getItem('cur_lan') !="en" ? 'jp':"en",
      isLoading2:false,
      samemanger:false,
      ed:[0,0,0,0,0,0,0],
      children_list:[],
      confirms:[0,0,0,0],
      errormail:0,
      new_mail:'',
      can_add:0,
      can_update:0,
      user_level:0,
      mail_content:"",
      total_price:0,
      adduser:{email:'','担当者・姓':'','担当者・名':'','担当者・部署名':'','担当者・役職':''},
      upduser:{email:'','担当者・姓':'','担当者・名':'','担当者・部署名':'','担当者・役職':''},
      email:localStorage.getItem('email'),
      default_pid:localStorage.getItem('email'),
      user_status_list:['正常','仮登録','未登録'],
      usertype:'',
    }
  },
  mounted() {

    this.cur_lan=localStorage.getItem('cur_lan') !="en" ? "jp":"en";
    let creis_language_data=JSON.parse(localStorage.getItem('creis_language_data'));
    this.lan=creis_language_data['menu_data_'+this.cur_lan]
    this.cur_step=localStorage.getItem('cur_step')!=null ? localStorage.getItem('cur_step'):1;
    this.user_status_list=[this.lan['user_status_0'],this.lan['user_status_1'],this.lan['user_status_2']];
    this.uinfo=JSON.parse(localStorage.getItem('uinfo'));
    var uinfo=JSON.parse(localStorage.getItem('uinfo'));
    this.current_uid=this.$route.query.current_uid != undefined ? this.$route.query.current_uid : '';
    //console.log(this.current_uid);
    if ( (localStorage.getItem('groups') == 'admin' && this.current_uid!='' && this.current_uid=='manageruser' ) || ((uinfo['インダスマーケット'] || uinfo['オフィスマーケット'] || uinfo['リテールマーケット'])  &&  uinfo['状態']==1 && uinfo['契約期限 終了日'] != null && uinfo['契約期限 終了日'] >= getNdate()  && (uinfo['step']==7 || uinfo['step']=='6.1')) ) {
      this.user_level=1;
    }
    if(localStorage.getItem('groups') == 'admin' && this.current_uid!='' && this.current_uid=='manageruser' ){
      //console.log(localStorage.getItem('manageruser'));
      this.uinfo=JSON.parse(localStorage.getItem('manageruser'));
      //localStorage.setItem('manageruser','');
      this.usertype='admin';  //管護者経由込み用。経由
      //console.log(this.uinfo);
      this.email=this.uinfo['Email'];
      this.default_pid=this.uinfo['Email'];
    }
       axios.get('getSignUrl',{params:{url:this.uinfo['attachment']} })
      .then(response => {
        this.isLoading2=false;
        this.uinfo['attachment']=response.data
      })
      
      this.creisChildrenList();
   
   
  },
  methods: {
    initpdf: function(src){
     return encodeURIComponent(src);
    },
    saveppid:function(){
      if(confirm(this.lan.alter_change_parent)){
        var editData={current_user:this.uinfo['メールアドレス'],default_pid:this.default_pid}
        axios.post('creisUserUpdatePid', editData
        )
        .then((response) => {
          console.log(response);
          if(this.current_uid!='manageruser'){
            this.logout();
          }else{
            window.location.reload();
          }
         
        })
        .catch(error => {
     
          console.log(error)
        });
      }

    },
    logout:function (){
        localStorage.removeItem('id_token');
        localStorage.removeItem('groups');
        localStorage.removeItem('name');
        localStorage.removeItem('uinfo');
        localStorage.removeItem('from_site');
        localStorage.removeItem('email');

        window.location.href = 'https://auth.creis.jp/login?token=logout';//'https://auth.cbredata.com/login?client_id=1gcmfopbf7qtttffm228f52657&response_type=token&redirect_uri=https://cbredata.com/jp/index.php';                       
        
    },
    usesame: function(){
      this.samemanger=!this.samemanger;
      if(this.samemanger){
        this.uinfo['姓']=this.uinfo['担当者・姓']
        this.uinfo['名']=this.uinfo['担当者・名']
        this.uinfo['部署名']=this.uinfo['担当者・部署名']
        this.uinfo['役職']=this.uinfo['担当者・役職']
      }
    },
    cannel:function(){
      this.can_add=0;
      this.can_update=0;
    },
    nextstep:function(step){
      
      if(step==2 && this.cur_step==1){
        this.saveuser(0);
        return false;
      }
      
      localStorage.setItem('cur_step',step);
      this.cur_step=step;

       if(this.cur_step==5){
         if(this.confirms[0]!=1 || this.confirms[1]!=1 ||this.confirms[2]!=1 || this.confirms[3]!=1){
           alert(this.lan.check_all_box);
           this.cur_step=4;
           return;
          }
          var editData={email:localStorage.getItem('email')}
          axios
        .post('creisUserSendEmail', editData
        )
        .then((response) => {
          console.log(response);
               
        })
        .catch(error => {
     
          console.log(error)
        });
       }
       if(this.cur_step==3 && this.children_list.length==0){
        this.creisChildrenList();
      }
    },
    check_empty(){
      var check_feilds=['会社名','郵便番号','都道府県','住所','担当者・姓','担当者・名','担当者・部署名','担当者・役職'];
      let status=0;
      this.ed=[0,0,0,0,0,0,0,0]
      for(var d in check_feilds){
        if(this.uinfo[check_feilds[d]]==null || this.uinfo[check_feilds[d]]=='' || this.uinfo[check_feilds[d]]==' '){
          status=1;
          this.ed[d]=1;
        }
      }
      if(!this.uinfo['オフィスマーケット'] && !this.uinfo['インダスマーケット'] && !this.uinfo['リテールマーケット']){
         status=1;
         alert(this.lan.select_one_service);
      }
      return status;
    },
    autoaddress:function(){
      if(this.uinfo['郵便番号'].length>0){
        this.isLoading2=true;
 axios.get('addresByPostcode',{params:{postcode:this.uinfo['郵便番号']} })
      .then(response => {
        this.isLoading2=false;
        if(response.data.status>0){
          console.log(response.data.data);
          this.uinfo['都道府県']=response.data.data.state;
          this.uinfo['住所']=response.data.data.city+' '+response.data.data.street;
          //this.uinfo['建物名']='';
        }else{
          this.uinfo['都道府県']='';
          this.uinfo['住所']='';
        }

      })
      
      }else{
        this.uinfo['都道府県']='';
        this.uinfo['住所']='';
        this.uinfo['建物名']='';
      }
    },saveuser(type){
      if(type==0 && this.check_empty()){
        return false;
      }
      
      let editData = this.uinfo
      this.isLoading2 = true;
      editData['オフィスマーケット']=editData['オフィスマーケット']==true ? 1:0;
      editData['インダスマーケット']=editData['インダスマーケット']==true ? 1:0;
      editData['リテールマーケット']=editData['リテールマーケット']==true ? 1:0;

      editData.updata_type=type==1 ? 'admin':'user_apply';
      editData.language=this.cur_lan;
      editData.step=this.cur_step+1
      editData.mail_content=this.mail_content;
      editData['建物名']=editData['建物名']=='' ? ' ':editData['建物名'];
      axios
        .post('creisUserUpdate', editData
        )
        .then((response) => {
          
          if(response.data.status){
            localStorage.setItem('uinfo',JSON.stringify(response.data.data));
            this.uinfo=response.data.data;
            this.uinfo['オフィスマーケット']=this.uinfo['オフィスマーケット']==1 ? true:false;
      
            this.uinfo['インダスマーケット']=this.uinfo['インダスマーケット']==1 ? true:false;
            
            this.uinfo['リテールマーケット']=this.uinfo['リテールマーケット']==1 ? true:false;
            if(type==0){
              axios.get('getSignUrl',{params:{url:this.uinfo['attachment']} })
              .then(response => {
                 this.uinfo['attachment']=response.data
                 this.cur_step=2;
                 this.nextstep(2);
              })
            }
          }
         
          this.isLoading2 = false
          
        })
        .catch(error => {
     
          this.isLoading2 = false
          console.log(error)
        });
    },addChlldrenUser(){
      var reg=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      if(!reg.test(this.adduser.email)){
        alert(this.lan.email_error);
        return false;
      }
      if(confirm(this.lan.alter_confirm_save)){
      this.isLoading2 = true;
      var editData=this.adduser;
      editData['corpid']=this.uinfo['ユーザーID'];
      editData.mail_content=this.mail_content;
      axios
        .post('creisUserUpdateEmail', editData
        )
        .then((response) => {
          this.isLoading2 = false
          if(response.data.status){
           this.children_list.push({'メールアドレス':editData.email,'担当者・姓':editData['担当者・姓'],'担当者・名':editData['担当者・名'],'担当者・部署名':editData['担当者・部署名'],'担当者・役職':editData['担当者・役職'],'user_status_name':this.user_status_list[1],'user_status':1})
           this.adduser={email:'','担当者・姓':'','担当者・名':'','担当者・部署名':'','担当者・役職':''};
            this.can_add=0;
          }else{
            alert(this.lan[response.data.msg]);
          }
                  
        })
        .catch(error => {
     
          this.isLoading2 = false
          console.log(error)
        });
      }
    },
    editChildrenUser(index){
      this.cur_index=index;
      this.upduser={'メールアドレス':this.children_list[index]['メールアドレス'],'uptype':'update',email:this.children_list[index]['メールアドレス'],'担当者・姓':this.children_list[index]['担当者・姓'],'担当者・名':this.children_list[index]['担当者・名'],'担当者・部署名':this.children_list[index]['担当者・部署名'],'担当者・役職':this.children_list[index]['担当者・役職']};
      
      this.can_update=1;
      this.can_add=0;
    },
    updateChlldrenUser(){
      if(confirm(this.lan.alter_confirm_save)){
      axios
        .post('creisUserUpdateEmail', this.upduser
        )
        .then((response) => {
          this.isLoading2 = false
          if(response.data.status){
           this.children_list[this.cur_index]=this.upduser;
            this.can_update=0;
          }else{
            alert(this.lan[response.data.msg]);
          }
                  
        })
        .catch(error => {
     
          this.isLoading2 = false
          console.log(error)
        });
      }
    },
    resendMail(index){
      this.cur_index=index;
      this.upduser={'メールアドレス':this.children_list[index]['メールアドレス'],email:this.children_list[index]['メールアドレス'],'担当者・姓':this.children_list[index]['担当者・姓'],'担当者・名':this.children_list[index]['担当者・名'],'担当者・部署名':this.children_list[index]['担当者・部署名'],'担当者・役職':this.children_list[index]['担当者・役職'],'user_status_name':this.user_status_list[1],'user_status':1};
      if(confirm(this.lan.alter_confirm_save)){
      axios
        .post('resend_email', this.upduser
        )
        .then((response) => {
          this.isLoading2 = false
          if(response.data.status){
           this.children_list[this.cur_index]=this.upduser;
            this.can_update=0;
          }else{
            alert(this.lan[response.data.msg]);
          }
                  
        })
        .catch(error => {
     
          this.isLoading2 = false
          console.log(error)
        });
      }
    }
    ,deleteChildrenUser(index){
      if(confirm(this.lan.alter_confirm_del)){
      var editData={email:this.children_list[index]['メールアドレス'],corpid:this.uinfo['ユーザーID']}
      
      axios
        .post('creisUserDelete', editData
        )
        .then((response) => {
          console.log(response.data)
          this.isLoading2 = false
          if(response.data.status){ 
            this.children_list.splice(index, 1)
          }else{
            alert(this.lan[response.data.msg]);
          }
        })
        .catch(error => {
     
          this.isLoading2 = false
          console.log(error)
        });
      }
    },creisChildrenList(){
      axios
        .get('creisChildrenList',{params:{pid:this.uinfo['ユーザーID']}})
        .then((response) => {
          console.log(response.data)
          this.children_list=response.data.data.map(function(item){
            item['user_status_name']=this.user_status_list[item['user_status']];
            return item;
          }.bind(this));
          this.mail_content=response.data.mail_content;
        })
        .catch(error => {
     
          this.isLoading2 = false
          console.log(error)
        });
    },
    downloadData:function(){
      location.href=this.uinfo.attachment;
    },
    formart_money_int:function (num) {
      
      if(isNaN(num) || num==null || num==0){
       return num=='' || num==null ?'-':num;
     }
     var result = '', counter = 0;
     //if(parseInt(num) == num) return num;
     num=num.toString();
     var numa=num.split('.');
     num=numa[0]
     for (var i = num.length - 1; i >= 0; i--) {
        counter++;
       result = num.charAt(i) + result;
       if (!(counter % 3) && i != 0 && num.charAt(i-1)!='-') { result = ',' + result; }
     }
     return result;
   }
  },
  watch:{
    
  $route(){
    this.cur_lan=localStorage.getItem('cur_lan') !="en" ? "jp":"en";
    let creis_language_data=JSON.parse(localStorage.getItem('creis_language_data'));
    this.lan=creis_language_data['menu_data_'+this.cur_lan]
   
  }
},
}
</script>
<style scoped>
input::-webkit-input-placeholder{
            color:#CCC;
        }
        input::-moz-placeholder{   /* Mozilla Firefox 19+ */
            color:#CCC;
        }
        input:-moz-placeholder{    /* Mozilla Firefox 4 to 18 */
            color:#CCC;
        }
        input:-ms-input-placeholder{  /* Internet Explorer 10-11 */ 
           color:#CCC;
        }
.line{margin: 0;
    width: 98%;
    display: table;
    margin-top: -1px;}
.line .left-box{    color: #000;   min-height: 40px; padding: 8px;
    border: 1px solid #ccc;background-color:#edf0f2; }
.left-box{width: 330px;float: left;}
.title,h4{color:#003f2d;}
.right-box{margin-left:-1px;width: 68%;    min-height: 40px; float: left;  padding: 5px 10px;
    border: 1px solid #ccc;}
.content{background: #fff;padding: 20px;position: relative;}
.btn{color:#fff;background: #042a2d;margin:10px 20px;}
.btn-bottom{margin:50px auto 0 auto;}
.required{font-size: 12px;    line-height: 12px;
    padding: 1px; border: 1px solid #ff0000; font-weight: normal; float: right; color: #ff0000;}
.steps{margin-bottom: 30px;width: 96%;margin:2%}
.ulist{border-collapse: collapse !important;width: 850px;margin-top: 15px;
   border: none !important;}
.ulist td{border: solid #666 1px !important;padding:5px}
.ulist td .btn{margin:5px auto !important;}
.thead td{ background:#ddd ;color: #003f2d;text-align: center;}
.autorun{color: #003f2d; cursor: pointer;margin-left: 20px;}
.load{position: absolute; top:0;left:0;width: 100%;height: 100%;z-index: 999;font-size: 50px;background: rgba(255,255,255,0.7);color: #333;text-align: center;line-height: 500px;}

.autorun:hover{text-decoration: underline;}
hr{    width: 89%;margin: 20px 0 5px 0; height: 1px;background: #eee;border-top:1px solid #666 !important;}
.error{border:1px solid #ff0000}
.pdf-viewer{width:100%;height:470px; border: 0;}
.box-text{border:1px solid #ddd;min-height:250px;border-radius: 5px;}
.ubox{border: 1px solid #ddd; border-radius: 5px;display:inline-block;padding: 5px 10px;margin: 5px;clear: both;}
.btn-danger{background: #ff0000 !important;}
.addnew{clear: both;}
.check-box{padding: 20px;line-height: 30px;}
.dd{position: relative;}

    .price-right span{color:#ff0000;font-weight: bolder;}
    
  .content{    min-height: 630px;}
.linebox{padding-left: 25px;}
.linebox .title div{padding-left: 25px;}
#print img{max-width: 150px;}
body textarea{    width: 600px;
    height: 150px;}
    .no_premision{    background-color: #fff;
    padding: 1.5rem;
    box-shadow: 0 0.75rem 6rem #efefef;
    margin-bottom: 24px;
    border-radius: .25rem; text-align: center;}
</style>